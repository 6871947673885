<template>
  <div>
    <el-dialog
      :visible.sync="showRatesDialog"
      :before-close="handleClose"
      width="60%"
      center
    >
      <div class="row">
        <div class="col-md-4">
          <div v-loading="isUploadingFile">
            <p style="font-size: 1.2em; font-weight: 600;">Add Rates</p>
            <el-form
              :model="ratesForm"
              :rules="activityRules"
              ref="ratesForm"
              class="demo-ratesForm2"
            >
              <div class="row">
                <div class="col-md-12">
                  <label>Numbers of Travelers</label>
                  <el-form-item prop="number_of_travelers">
                    <el-input-number
                      v-model.number="ratesForm.number_of_travelers"
                      @change="handleChange"
                      :min="1"
                      :max="10"
                      class="w-100"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-12">
                  <label>Rate Amount</label>
                  <el-form-item prop="rate_amount">
                    <el-input v-model.number="ratesForm.rate_amount"></el-input>
                  </el-form-item>
                </div>
              </div>

              <hr />
              <el-form-item>
                <el-button
                  type="primary"
                  class="w-100"
                  @click="submitratesForm('ratesForm')"
                >Add Rate</el-button>
              </el-form-item>
            </el-form>
            <div class="text-center">
              <div class="text-center">
                <small>© 2022 Ihamba Adventures, All rights reserved.</small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <p style="font-size: 1.2em; font-weight: 600;">Rates</p>
          <div class="form-group">
            <label>Items</label>
            <!-- {{ safari_tour_rates }} -->
            <el-table
              ref="billingItemsTableRef"
              :data="safari_tour_rates"
              border
              style="width: 100%"
            >
              <el-table-column label="Number of Travelers">
                <template #default="scope"> {{ scope.row.number_of_travellers }} </template>
              </el-table-column>
              <el-table-column label="Rate Amount(USD)">
                <template #default="scope">
                  <input
                    class="legacy-input bg-white"
                    v-model="scope.row.rate_amount"
                    style="border: none"
                  />
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showRatesDialog: false,
      isUploadingFile: false,
      value: "",
      loading: false,
      loadingError: false,
      safari_tour_rates: [],
      ratesForm: {
        number_of_travelers: "",
        rate_amount: "",
        package_id: `${this.packageId}`,
      },

      activityRules: {
        number_of_travelers: [
          {
            required: true,
            message: "Number of travelers required",
            trigger: "blur",
          },
        ],

        rate_amount: [
          {
            required: true,
            message: "Rate Amount required",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    packageId: {
      required: true,
      type: String,
    },
    isRatesDialogVisible: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    isRatesDialogVisible() {
      if (this.isRatesDialogVisible == true) {
        this.showRatesDialog = true;
        this.getRates();
      } else {
        this.showRatesDialog = false;
      }
    },
  },

  methods: {
    handleClose() {
      (this.showRatesDialog = false), this.$emit("closeRatesDialog");
    },

    async submitratesForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.isUploadingFile = true;
            let request = await this.$http.post(
              `api/safari/package/rates/add`,
              {
                package_id: `${this.packageId}`,
                number_of_travellers: this.ratesForm.number_of_travelers,
                rate_amount: this.ratesForm.rate_amount,
                // package_id: "12",
                // number_of_travellers: "2",
                // rate_amount: "2720",
              }
            );
            if (
              request.data.success &&
              request.data.message == "SAFARI_RATE_ADDED"
            ) {
              this.getRates();
              this.$notify({
                title: "Success",
                message: "Rates Added Successfully",
                type: "success",
              });
            } else if (request.data.message == "SAFARI_PACKAGE_RATE_EXISTS") {
              this.$notify({
                title: "Duplicate",
                message: "Package Rate Exists",
                type: "warning",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            this.$notify({
              title: "Upload Failed",
              message: "Unable to Add Rates now, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },

    handleChange(value) {
      console.log(value);
    },

    async getRates() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `api/safari/package/rates/get_all/${this.packageId}`
        );
        if (request.data.success) {
          // this.lecturerCourseUnits = request.data.lecturer_course_units;
          this.safari_tour_rates = request.data.safari_tour_rates;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch Rates now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style>
</style>